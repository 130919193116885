





/* FONTS */
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');
p{
  font-family: 'Libre Baskerville', serif;
  font-size: 1.1rem;
  line-height: 1.8;
}
/* DAVID STYLES */
.tagline{
  font-size: 30px;
  text-align: center;
  color: white;
  text-shadow: 0px 0px 10px #000;
}
.tagline span{
  color: #c3d4e5;
}


.header-wrap{
  background-image: url('./assets/bg-header-wrap-home.jpg');
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  width: 100%;

}
.header-page{
  width:1260px;
  /* display:flex; */
  /* justify-content: center; */
}

.logo-nav-container{
  display: flex;
  /* justify-content: center; */
  justify-content: space-evenly;
  
}


.head-nav{
  display:flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.head-nav ul{
  display: flex;
  list-style-type: none;
  
}
.law-blog{
  display: flex;
  border:1px solid transparent;
  border-radius: 2px;
  background-color: #003c80;
  height: 30px;
  align-items:center
  
}

.law-blog:hover{
  background-color: #002450;
  border: 1px solid #003c80;
border-style: inset;
}
.head-nav li{
  padding-left:20px;
  padding-right:20px;
}
.head-nav ul a{
  text-decoration: none;
  color:#fff;
}
.head-nav li{
  text-decoration: none;
  color:#fff;
}
.head-nav li:hover{
  cursor: pointer;
}

.head-call{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  color:white;
}
.call-num{
  font-size: 33px;
  color: orange;
}

/* DAVID PAGE STYLES */
.david-Prof-Pic{
  background-image: url(./assets/david2.JPG);
  /* width: 144px; */
  width:200px;
  height:300px;
  background-repeat: no-repeat;
  background-size: contain;
  /* height: 216px; */
  box-shadow: 3px 2px 2px #002d61;
}



.about-attorney{
  width:60%;
}
.lawyer-header{
  display: flex;
  /* flex-direction: row-reverse; */
  justify-content: space-between;
}
.attorney-infos{
  display:flex;
  justify-content: space-between;
  align-items: center;
}

.attorney-infos button{
  height:40px;
  width:40%;
  border:none;
  background-color: #0b4f9b;
  color:white;
  border-radius: 2px;
  font-size: medium;
}

.attorney-infos button:hover{
cursor: pointer;
background-color: #083e7c;
  
}


.attorneyProfileSection h3{
color:#083e7c;
font-size: xx-large;
}


/**** BODY TEXT STYLES ************/


/* MAIN LAW NAV STYLES */


.practice-areas-nav{
  display:flex;
  width:100%;
}
.practice-areas-nav ul{
  display:flex;
  list-style: none;
  width:100%;
  justify-content: center;
  
}
.practice-areas-nav ul li{
  width:179px;
  height:176px;
  border-right: 1px solid #f2f2f2;
}
.practice-areas-nav a{
  color:black;
  
}
.practice-areas-nav ul li:hover{
  background-color:#133053;
}
.practice-areas-nav a:hover{
  color:white;
}



.family-law-nav{
  display:flex;
  flex-direction: column-reverse;
  width:99px;
  height: 96px;
  padding:40px;
  background: url(./assets/n-prac-fami.png);
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 40px;
  text-align: center;
  text-decoration: none;

}
.appeals-law-nav{
  display:flex;
  flex-direction: column-reverse;
  width:99px;
  height: 96px;
  padding:40px;
  background: url(./assets/n-fami-appe.png);
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 40px;
  text-align: center;
  text-decoration: none;

}
.business-law-nav{
  display:flex;
  flex-direction: column-reverse;
  width:99px;
  height: 96px;
  padding:40px;
  background: url(./assets/n-fami-busi.png);
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 40px;
  text-align: center;
  text-decoration: none;

}
.civil-law-nav{
  display:flex;
  flex-direction: column-reverse;
  width:99px;
  height: 96px;
  padding:40px;
  background: url(./assets/n-fami-civi.png);
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 40px;
  text-align: center;
  text-decoration: none;

}
.judgement-law-nav{
  display:flex;
  flex-direction: column-reverse;
  width:99px;
  height: 96px;
  padding:40px;
  background: url(./assets/n-fami-judg.png);
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 40px;
  text-align: center;
  text-decoration: none;

}
.injury-law-nav{
  display:flex;
  flex-direction: column-reverse;
  width:99px;
  height: 96px;
  padding:40px;
  background: url(./assets/n-fami-pers.png);
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 40px;
  text-align: center;
  text-decoration: none;

}

.estate-law-nav{
  display:flex;
  flex-direction: column-reverse;
  width:99px;
  height: 96px;
  padding:40px;
  background: url(./assets/n-fami-real.png);
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 40px;
  text-align: center;
  text-decoration: none;

}

/* CHANGING BODY STYLES ********* */
.changing-body{
  width:100%;
  display: flex;
  justify-content: center;
  
}
.section-one-title{
  color:#003c80;
  
}
.page-title{
  color:#003c80;
}

.practice-areas-col{
  display: flex;
  flex-direction: column;
  width: 390px;
  height: 427px;
}
.subnavs{
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  border-radius: 2px;
  height: 61px;
  text-decoration: none;
  text-indent: 14px;
  color:white;
  background-color: #002450;
  border-bottom: 1px solid white;
}
.subnavs:hover{
  background-color: #0b4f9b;
}
.family-subnavs {
  display:flex;
  align-items: center;
  height:47px;
  text-decoration: none;
  text-indent: 14px;
  border-bottom: 1px solid #d2d2d2;
  border-left: 1px solid #cfd8e2;
  border-right: 1px solid #cfd8e2;
  color: black;
}
.family-subnavs:hover{
  background-color: #0b4f9b;
  color:white;
}
.family-subnavs-PRIM{
  display:flex;
  align-items: center;
  height:47px;
  text-decoration: none;
  text-indent: 14px;
  border-bottom: 1px solid #d2d2d2;
  border-left: 1px solid #cfd8e2;
  border-right: 1px solid #cfd8e2;
  background-color: #0b4f9b;
  color:white;
}
.family-subnavs-PRIM div{
  margin-left: 14px;
  background-image: url(./assets/bg-rightarrow.png);
  width: 6px;
  height: 10px;
  background-repeat: no-repeat;
}
.family-subnavs div{
  margin-left: 14px;
  background-image: url(./assets/bg-rightarrow.png);
  width: 6px;
  height: 10px;
  background-repeat: no-repeat;
  
}
.family-subnavs div:hover{
 filter:invert(100%)
}

.interchanging-body{
  width:100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.body-wrapper{
  width:1260px;
  display:flex;
  flex-direction: column;
  justify-content: center;
}
.section-one-wrapper{
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  justify-content: center;

}

.section-one-peel{
  display:flex;
  justify-content: center;
  flex-direction: column;
  width:1260px;
}
.section-one-container{
  display:flex;
  justify-content: space-between;
  
}
.section-two-wrapper{
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  justify-content: center;
  background-color: #002450;
}
.section-two-peel{
  display:flex;
  flex-direction: row;
  justify-content: center;
  width:1260px;
}
.section-two-topping-1{
  width:65%;
}
.section-two-topping-2{
  background-image: url('./assets/bg-second-image.jpg');
  width:765px;
}
.section-two-wrapper h3{
  color:#faa62d;
  font-size: 36px;
}
.section-two-wrapper h4{
  color:white;
  font-size: 36px;
}
.section-two-wrapper p{
  color:white;
}


/* .image-box{
  background-image: url('./assets/bg-second-image.jpg');
  width: 100%;
} */
.para0{
width:90%;
}
.para1{
  width:90%;
}


.section-two-content{
  display:flex;
}
.section-three-wrapper{
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  justify-content: center;

}
.section-three-peel{
  display:flex;
  flex-direction: column;
  justify-content: center;
  width:1260px;
}

.first-par{
  width: 612px;
}
.second-par{
  width:612px;
}

.section-three-wrapper{
  text-align: center;
}


.section-three-wrapper h3{
  color:#01254e;
  font-size: 36px;
  
}


/* FORM STYLES */

.form-wrapper{
  background-image: url('./assets/bg-shortform.jpg');
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom:40px;
}
.form-wrapper h4{
  color:white;
  font-size: 36px;
}
.form-wrapper p{
  color:white;
  /* font-size: 36px; */
}
.form-peel{
  width:1260px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.input-container{
  display:flex;
  flex-direction: column;
}

.req-form{
  display:flex;
  flex-direction: column;
}

.req-form-half-one{
  display:flex;
  width:100%;
  justify-content: space-between;
}
.req-form input{
padding:10px;
font-family: 'Libre Baskerville', serif;
  /* font-size: 1.1rem; */
  /* line-height: 1.8; */
margin:5px;
}
.req-form textarea{
  margin-top: 5px;
  height:134px;
  width:48.5%;
  text-indent: 5px;
  font-family: 'Libre Baskerville', serif;
  font-size: 1.1rem;
  line-height: 1.8;
  
  
  
}
.inputs-req{
  display:flex;
  flex-direction: column;
  width:48.5%;
}
.req-form-half-two button{
  background-color: #faa62d;
  color: #fff;
  width: 210px;
  height: 56px;
  font-size: 1.3rem;
  float:right;

}

.req-form-half-two button:hover{
  background-color: white;
  color:navy;
  cursor: pointer;
  border-style: inset;
}



/* FOOTER */

.contact-info-map{
  background-image: url('./assets/bg-map.jpg');
  width:100%;
  height:485px;
  display: flex;
  justify-content: center;
}
.address-box{
  margin:80px;
  width:80%;
  
  color:#fff;
  font-size: 1.5rem;
}
.address{
  float:left;
  background-color: #002450;
  width:40%;
  text-align: center;
  padding-bottom:20px;
}
.address p{
  margin-bottom: 2px;
  margin-top:2px;
  font-size: 1.2rem;
}



.call-today{
  background-color: #002450;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 30px;
}
.call-today h3{
  color: #fff;
  font-size: 24px;
  margin-bottom: 0;
  
}

.call-today h2{
  color:#faa62d;
  font-size: 48px;
  margin-top: 0;
  margin-bottom: 0;
  
}
.copyright{
  padding-top: 40px;
  text-align: center;
  font-size: .7rem;
}

.changing-body{
  display: flex;
  width:100%;

}
.column-main-wrapper{
  display: flex;
  width:60%;
}
.about-pages-wrapper{
  display:flex;
  width: 1260px;
  flex-direction: column;
}
.under-nav-page{
  display:flex;
  justify-content: space-evenly;
  margin-top:20px ;
  
}
.column-main{
  display:flex;
  flex-direction: column;
}

/* MEDIA STYLES BELOW */

@media (max-width:1260px){
  .brand-logo{
    display:flex;
    justify-content: center;
  }
  .brand-logo img{
    width: 40vw;
  }
.logo-nav-container{
  flex-direction: column;
}

.tagline h1{
  font-size: 120%;
}
.head-nav{
  justify-content: center;
  
}
.head-nav ul{
  padding-left:0;
  
}

.practice-areas-nav{
  overflow-x: scroll;
}
.practice-areas-nav::-webkit-scrollbar{
  height:.5em;
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
.practice-areas-nav::-webkit-scrollbar-thumb{
  background-color: darkgrey;
  /* outline: 1px solid slategrey; */
  border-radius:4px;
}
.practice-areas-nav ul li{
  height:80%;
}

.practice-areas-nav ul li a{
  padding:0;
  background-position-y: 20px;
  font-size: 70%;
  width:100%;
  
}
/* .practice-areas-nav ul li:hover{
  background-color:white;
}
.practice-areas-nav ul li a:hover{
  background-color:white;
} */
.practice-areas-nav a:hover{
  background-color: #133053;
  /* color:black; */
}

.practice-areas-nav ul li:active{
  background-color:#133053;
}
.practice-areas-nav ul li a:active{
  
  background-color:#133053;
  color:white;
}

.about-pages-wrapper{
  width:100%;
}
.column-main-wrapper{
  
  width:70%;
}

/* SIDE MENU STYLES*********** */


.column-menu-wrapper{
  width:20%;
  
}

.practice-areas-col{
  width:100%;
  
}
.subnavs{
  /* width:110px; */
  border-radius: 2px;
  margin-bottom: 3px;
  height: 35px;
}
.family-subnavs{
  border-radius: 2px;
  margin-bottom: 1px;
  height: 35px;
}
.family-subnavs p{
  
  font-size: 80%;
  font-family: sans-serif;
}
.family-subnavs-PRIM p{
  font-size: 80%;
  font-family: sans-serif;
}
.subnavs p{
  font-size: 80%;
  text-align: center;
  /* text-indent: 0; */
  font-family: sans-serif;
}

.interchanging-body{
  width:100%;
}


.section-one-title{
  width:100%;
}
.section-one-wrapper{
  width:100%;
  
}
.section-one-peel{
  width:90%;
  justify-content: center;
}
  .section-one-container{
    flex-direction: column;
    
    
  }
  
.content{
  display: flex;
  justify-content: center;
}
.first-par{
  display:flex;
  justify-content: center;
  width:100%;
}
.second-par{
  display:flex;
  justify-content: center;
  width:100%;
}

.section-two-wrapper{
  width:100%;
}
.section-two-peel{
  width:90%;
}
.section-two-topping-1{
  width:100%;
}
  .section-two-topping-2{
    display:none;
  }

  .section-two-content{
    flex-direction: column;
  }

  .section-three-wrapper{
    width: 100%;
  }
.section-three-peel{
  width:90%;
}

.form-wrapper{
  width: 100%;
}
.form-peel{
  width:90%;
}

.contact-info-map{
  width:100%;
 background-position-x: -900px;
}
.address-box{
  margin-top: 0px;
  width:90%;
}
.address{
  width:100%;
  padding:0;
}
}

@media (max-width:1100px) {
  .family-subnavs p{
    font-size: 70%;
  }
  .family-subnavs-PRIM p{
    font-size: 70%;
  }
  .subnavs p{
    font-size: 70%;
    text-align: center;
    
  }
}

@media (max-width:980px) {
  .family-subnavs p{
    font-size: 60%;
  }
  .family-subnavs-PRIM p{
    font-size: 60%;
  }
  .subnavs p{
    font-size: 60%;
    text-align: center;
    
  }
}
@media (max-width:790px){
  .david-Prof-Pic{
    display:none;
  }
  
  .david-Prof-Pic1{
    background-image: url(./assets/davidprof.jpg);
    width: 144px;
    height: 216px;
    margin-bottom: 4px;
    box-shadow: 3px 2px 2px #002d61;

    
    
  }

  .about-attorney{
    width:100%;
  }
  .lawyer-header{
    display: flex;
    /* flex-direction: row-reverse; */
    justify-content: space-between;
  }
  .attorney-infos{
    display:flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .attorney-infos button{
    height:40px;
    width:40%;
    border:none;
    background-color: #0b4f9b;
    color:white;
    border-radius: 2px;
    font-size: medium;
  }
  
  .attorney-infos button:hover{
  cursor: pointer;
  background-color: #083e7c;
    
  }
}

@media (max-width:560px){
  .family-subnavs p{
    font-size: 50%;
    
  }
  .family-subnavs div{
    background:none;
    margin-left: 0;
  }
  .family-subnavs-PRIM div{
    background:none;
    margin-left: 0;
  }
  .family-subnavs-PRIM p{
    font-size: 50%;
    
  }
  .subnavs p{
    font-size: 50%;
    text-align: center;
    text-indent: 0;
    margin-left: 4px;
    
  }
  .brand-logo{
    display:flex;
    justify-content: center;
  }
  .brand-logo img{
    width: 80%;
  }
.logo-nav-container{
  flex-direction: column;
}

.tagline h1{
  font-size: 80%;
}

.practice-areas-nav{
  overflow-x: scroll;
  height:80%;
}
.practice-areas-nav ul{
  margin-left: 100px;
  padding:0;
}
.practice-areas-nav ul li{
  height:80%;
}
.practice-areas-nav ul li a{
  width:100px;
  /* height: 100%; */
  padding:0;
  background-position-y: 10px;
  font-size: 70%;
}
/* .practice-areas-nav ul li a:hover{
  background-color:#133053;
}
.practice-areas-nav a:hover{
  color:white;
} */

.interchanging-body{
  width:100%;
}


.section-one-title{
  width:100%;
}
.section-one-wrapper{
  width:100%;
}
.section-one-peel{
  width:90%;
  justify-content: center;
}
  .section-one-container{
    flex-direction: column;
    
  }
  
.content{
  display: flex;
  justify-content: center;
}
.first-par{
  display:flex;
  justify-content: center;
  width:90%;
}
.second-par{
  display:flex;
  justify-content: center;
  width:90%;
}

.section-two-wrapper{
  width:100%;
}
.section-two-peel{
  width:90%;
}
.section-two-topping-1{
  width:100%;
}
  .section-two-topping-2{
    display:none;
  }

  .section-two-content{
    flex-direction: column;
  }

  .section-three-wrapper{
    width: 100%;
  }
.section-three-peel{
  width:90%;
}

.form-wrapper{
  width: 100%;
}
.form-peel{
  width:90%;
}

.contact-info-map{
  width:100%;
 background-position-x: -900px;
}
.address-box{
  margin-top: 0px;
  margin-right:0;
  margin-left:0;
  width:100%;
}
.address{
  width:100%;
  padding:0;
  
}
.address p{
  font-size: 70%;
}

}



@media (max-width:480px){
  .practice-areas-nav ul{
    margin-left: 150px;
    padding:0;
  }

  

}
@media (max-width:430px) {
  .family-subnavs p{
    font-size: 50%;
    text-indent: 0;
    margin-left: 4px;
  }
  .family-subnavs-PRIM p{
    font-size: 50%;
    text-indent: 0;
    margin-left: 4px;

  }
  .subnavs p{
    font-size: 50%;
    text-indent: 0;
    margin-left: 4px;
    
  }
}
@media (max-width:360px){
  .practice-areas-nav ul{
    margin-left: 200px;
    padding:0;
  }
}